import React from 'react';
import {Layout} from 'antd';


import {Footer} from './components/Footer/index'
import {Header} from './components/Header/index'
import {Login} from './pages/Connexion/login'

const App: React.FC = () => {

    return (
        <Layout className="layout">
            <Header/>
            <Login/>
            <Footer/>
        </Layout>
    );
};

export default App;