// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ant-menu-overwflow-item ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-open ant-menu-submenu-active

.ant-menu-overwflow-item:hover,
.ant-menu-submenu:hover,
.ant-menu-submenu-horizontal:hover,
.ant-menu-submenu-open:hover,
.ant-menu-submenu-active:hover {
  color: white; /* Set the background color to transparent on hover */
}`, "",{"version":3,"sources":["webpack://./src/components/Header/style.css"],"names":[],"mappings":"AAAA;;;;;;;EAOE,YAAY,EAAE,qDAAqD;AACrE","sourcesContent":["ant-menu-overwflow-item ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-open ant-menu-submenu-active\n\n.ant-menu-overwflow-item:hover,\n.ant-menu-submenu:hover,\n.ant-menu-submenu-horizontal:hover,\n.ant-menu-submenu-open:hover,\n.ant-menu-submenu-active:hover {\n  color: white; /* Set the background color to transparent on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
