import "./style.css";
import {Button, Col, Divider, Dropdown, Input, Menu, MenuProps, Row, Space} from "antd";
import {
    DownOutlined,
    FacebookOutlined, HeartOutlined,
    InstagramOutlined,
    LinkedinOutlined, PhoneOutlined,
    SearchOutlined, ShoppingCartOutlined, UserOutlined,
    YoutubeOutlined
} from "@ant-design/icons";
import logo from "../../logo.svg";
import React from "react";

function Header() {
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorHeaderBottomText = "#FFFFFF"
    const colorHeaderButtonBg = "#7ED957"



    const category: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    Nautisme
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    Plein air
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    Aéronautique
                </a>
            ),
        },
    ];

    const SubMenu = Menu.SubMenu;

    const handleMenuClick = (e: { key: React.Key; keyPath: React.Key[]; item: React.ReactInstance }) => {
        console.log('Clicked:', e);
    }

    const subMenu1 = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1"><a>Accastillage</a></Menu.Item>
            <Menu.Item key="2"><a>Sécurité</a></Menu.Item>
        </Menu>
    );

    const subMenu2 = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="3"><a>Moteur</a></Menu.Item>
            <Menu.Item key="4"><a>Comfort</a></Menu.Item>
        </Menu>
    );

    const lang: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    Français
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    English
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    Español
                </a>
            ),
        },
    ];

    const currency: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    USD
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    GBP
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    CHF
                </a>
            ),
        },
    ];

    return (
        <div className={'header'} style={{backgroundColor: colorHeaderBottomText, height: '100%', lineHeight: '30px', paddingInline: '0'}}>
            <Row style={{color: colorHeaderText, marginBottom: '10px'}}>
                <Col flex={1} style={{justifyContent: 'end', display: 'inline-flex'}}>
                    <p style={{marginRight: '20px'}}>Marketplace de pièces de réemploi pour bateaux</p>
                </Col>
                <Col flex={1} style={{display: 'inline-flex'}}>
                    <p>Langue</p>
                    <Dropdown menu={{ items: lang }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()} style={{
                            justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                            marginLeft: '10px', position: 'relative', color: colorHeaderText}}>
                            <Space>
                                Français
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <div style={{borderLeft: '2px solid' + colorHeaderVerticalBar, height: '30px', marginLeft: '10px', position: 'relative'}}></div>
                    <p style={{marginLeft: '10px'}}>Devise</p>
                    <Dropdown menu={{ items: currency }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()} style={{
                            justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                            marginLeft: '10px', position: 'relative', color: colorHeaderText}}>
                            <Space>
                                EUR
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <div style={{borderLeft: '2px solid' + colorHeaderVerticalBar, height: '30px', marginLeft: '10px', position: 'relative'}}></div>
                    <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                            'center', alignItems: 'center', marginLeft: '10px', color: colorHeaderText}}>En savoir +</a>
                    <div style={{borderLeft: '2px solid' + colorHeaderVerticalBar, height: '30px', marginLeft: '10px', position: 'relative'}}></div>
                    <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                            'center', alignItems: 'center', marginLeft: '10px', color: colorHeaderText}}>Partenaires</a>
                    <div style={{borderLeft: '2px solid' + colorHeaderVerticalBar, height: '30px', marginLeft: '10px', position: 'relative'}}></div>
                    <p style={{marginLeft: '10px'}}>Suivez-nous !</p>
                    <Row>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', marginLeft: '10px'}}>
                            <a style={{color: colorHeaderText}}>
                                <FacebookOutlined style={{fontSize: '15px'}}/>
                            </a>
                        </Col>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', marginLeft: '10px'}}>
                            <a style={{color: colorHeaderText}}>
                                <InstagramOutlined style={{fontSize: '15px'}} />
                            </a>
                        </Col>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', marginLeft: '10px'}}>
                            <a style={{color: colorHeaderText}}>
                                <YoutubeOutlined style={{fontSize: '15px'}} />
                            </a>
                        </Col>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', marginLeft: '10px'}}>
                            <a style={{color: colorHeaderText}}>
                                <LinkedinOutlined style={{fontSize: '15px'}} />
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider style={{borderTop: '1px solid ' + colorHeaderVerticalBar, margin: '0', paddingInline: '30px'}}/>
            <Row style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
                <Col flex={1} style={{justifyContent: 'end', alignItems: 'center', position: 'relative', display: 'inline-flex'}}>
                    <img src={logo} style={{width: '75px'}}/>
                </Col>
                <Col flex={5}>
                    <Space.Compact style={{width: '75%', left: '10%', position: 'relative', height: '40px'}}>
                        <Dropdown menu={{ items: category }} trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()} style={{
                                justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                                position: 'relative', color: colorHeaderText,
                                borderLeft: '1px solid' + colorHeaderVerticalBar,
                                borderTop: '1px solid' + colorHeaderVerticalBar,
                                borderBottom: '1px solid' + colorHeaderVerticalBar, borderRadius: '5% 0% 0% 5%'}}>
                                <Space style={{marginRight: '10px', marginLeft: '10px'}}>
                                    Catégories
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                        <Input placeholder="Rechercher un produit..."/>
                        <Button type="primary" style={{color: colorHeaderText, backgroundColor:
                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px'}}><SearchOutlined /></Button>
                    </Space.Compact>
                </Col>
                <Col flex={2} style={{display: 'inline-flex', gap: '3%', alignItems: 'center', justifyContent: 'center'}}>
                    <a href={'tel:+33468229025'} style={{display: 'inline-flex'}}>
                        <PhoneOutlined style={{fontSize: '22px', color: colorHeaderText, display: 'inline-flex', alignItems: 'center', marginRight: '10px', transform: 'scaleX(-1)'}}/>
                        <p style={{color: colorHeaderText}}>04 68 22 90 25</p>
                    </a>
                    <a style={{display: 'inline-flex'}}>
                        <HeartOutlined style={{fontSize: '22px', color: colorHeaderText, display: 'inline-flex', alignItems: 'center', marginRight: '10px'}}/>
                        <p style={{color: colorHeaderText}}>Liste de souhaits</p>
                    </a>
                    <a style={{display: 'inline-flex'}}>
                        <UserOutlined style={{fontSize: '22px', color: colorHeaderText, display: 'inline-flex', alignItems: 'center', marginRight: '10px'}}/>
                        <p style={{color: colorHeaderText}}>Mon Compte</p>
                    </a>
                    <a style={{display: 'inline-flex'}}>
                        <ShoppingCartOutlined style={{fontSize: '22px', color: colorHeaderText, display: 'inline-flex', alignItems: 'center', marginRight: '10px'}}/>
                        <p style={{color: colorHeaderText}}>Panier</p>
                    </a>
                </Col>
            </Row>
            <Row style={{backgroundColor: colorHeaderText, marginTop: '10px', color: colorHeaderBottomText}}>
                <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center'}}>
                    <Dropdown overlay={
                        <Menu onClick={handleMenuClick}>
                            <SubMenu key="sub1" title="Nautisme">
                                {subMenu1}
                            </SubMenu>
                            <SubMenu key="sub2" title="Plein Air">
                                {subMenu2}
                            </SubMenu>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={(e) =>
                            e.preventDefault()} style={{color: colorHeaderBottomText, justifyContent: 'center',
                            display: 'inline-flex', alignItems: 'center', position: 'relative'}}>
                            Toutes Catégories <DownOutlined style={{marginLeft: '10px'}}/>
                        </a>
                    </Dropdown>
                </Col>
                <Col flex={3} style={{display: 'inline-flex', height: '60px'}}>
                    <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', color: colorHeaderBottomText}}>Accueil</a>
                    </Col>
                    <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', color: colorHeaderBottomText}}>Boutique</a>
                    </Col>
                    <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', color: colorHeaderBottomText}}>Contact</a>
                    </Col>
                </Col>
                <Col style={{display: 'inline-flex', backgroundColor: colorHeaderButtonBg, flex: '1 1', minWidth: '100px'}}>
                    <a style={{textAlign: 'center', display: 'inline-flex', margin: 'auto', color: colorHeaderBottomText}}>Déposer une annonce</a>
                </Col>
                <Col flex={3} style={{display: 'inline-flex'}}>
                    <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', color: colorHeaderBottomText}}>Promos</a>
                    </Col>
                    <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', color: colorHeaderBottomText}}>Meilleures Ventes</a>
                    </Col>
                    <Col flex={1} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center', color: colorHeaderBottomText}}>Nouveautés</a>
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

export {Header};