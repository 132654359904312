import "./style.css";
import {Button, Col, Divider, Dropdown, Input, MenuProps, Row, Space} from "antd";
import {
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    MailOutlined,
    PhoneOutlined, UpOutlined,
    YoutubeOutlined
} from "@ant-design/icons";
import React from "react";


function Footer() {

    const colorFooterBgContainer = "#09154C"
    const colorFooterText = "#FFFFFF"
    const colorDivider = "#222C5E"
    const colorFooterButtonBg = "#7ED957"

    const currentYear = new Date().getFullYear();

    const lang: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    Français
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    English
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    Español
                </a>
            ),
        },
    ];

    const currency: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    USD
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    GBP
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    CHF
                </a>
            ),
        },
    ];

    return (
        <div className={'footer'} style={{backgroundColor: colorFooterBgContainer, color: colorFooterText}}>
            <br/>
            <h1 style={{textAlign: 'center'}}>Inscrivez-vous à la newsletter et recevez 10% de réduction !</h1>
            <br/>
            <Space.Compact style={{width: '50%', left: '25%', position: 'relative'}}>
                <Input placeholder="Email"/>
                <Button type="primary" style={{color: colorFooterBgContainer, backgroundColor: colorFooterButtonBg}}>S'inscrire</Button>
            </Space.Compact>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Row style={{margin: '20px'}}>
                <Col flex={5}>
                    <li style={{listStyleType: 'none', fontSize: '24px'}}>Nous contacter</li>
                    <br/>
                    <Row>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center'}}><PhoneOutlined style={{fontSize: '32px',
                            transform: 'scaleX(-1)'}}/>
                        </Col>
                        <Col flex={4}>
                            <p>Lundi - Vendredi : 08h - 19h</p>
                            <p><a href={'tel:+33468229025'} style={{color: colorFooterText}}>04 68 22 90 25</a></p>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center'}}><MailOutlined style={{fontSize: '32px',
                            transform: 'scaleX(-1)'}}/></Col>
                        <Col flex={4}>
                            <p>Besoin d'aide ?</p>
                            <p><a href={'mailto:support@poseidonshop.fr'} style={{color: colorFooterText}}>support@poseidonshop.fr</a></p>
                        </Col>
                    </Row>
                </Col>
                <Col flex={5}>
                    <li style={{listStyleType: 'none', fontSize: '24px'}}>A propos</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Présentation</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Nos Valeurs</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Nos partenaires</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Captain Chercheur</li>
                </Col>
                <Col flex={5}>
                    <li style={{listStyleType: 'none', fontSize: '24px'}}>Infos</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Mon Compte</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Mode de Paiement</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Livraison</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Retour</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>F.A.Q.</li>
                </Col>
                <Col flex={5}><li style={{listStyleType: 'none', fontSize: '24px'}}>Mentions</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Mentions légales</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>CGV</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>CGU</li>
                    <br/>
                    <li style={{listStyleType: 'none', fontSize: '16px'}}>Politique de confidentialité</li>
                </Col>
                <Col flex={5}>
                    <li style={{listStyleType: 'none', fontSize: '24px', textAlign: 'center'}}>Suivez-nous !</li>
                    <br/>
                    <Row>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center'}}>
                            <FacebookOutlined style={{fontSize: '30px'}}/>
                        </Col>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center'}}>
                            <InstagramOutlined style={{fontSize: '30px'}} />
                        </Col>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center'}}>
                            <YoutubeOutlined style={{fontSize: '30px'}} />
                        </Col>
                        <Col flex={1} style={{position: 'relative', display: 'inline-flex', justifyContent:
                                'center', alignItems: 'center'}}>
                            <LinkedinOutlined style={{fontSize: '30px'}} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <br/>
            <br/>
            <Divider style={{borderTop: '1px solid ' + colorDivider}}/>
            <Row style={{margin: '20px'}}>
                <Col flex={1}>
                    <p >© {currentYear} POSEIDON. Tous droits réservés</p>
                </Col>
                <Col flex={1} style={{display: 'inline-flex', justifyContent: 'end'}}>
                    <p style={{textAlign: 'end', color: '#B5B9C9'}}>Devise</p>
                    <Dropdown menu={{ items: currency }} placement="top" trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()} style={{
                            justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                            marginLeft: '10px', position: 'relative', color: colorFooterText}}>
                            <Space>
                                EUR
                                <UpOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                    <p style={{marginLeft: '25px', color: '#B5B9C9'}}>Langue</p>
                    <Dropdown menu={{ items: lang }} placement="top" trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()} style={{
                            justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                            marginLeft: '10px', position: 'relative', color: colorFooterText}}>
                            <Space>
                                Français
                                <UpOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                </Col>
            </Row>
            <br/>
        </div>
    );
}

export {Footer};