import React, {useState} from 'react';
import login_bg from '../../img/login_bg.png'
import {Button, Checkbox, Divider, Form, Input} from 'antd';
import {
    GoogleOutlined,
} from "@ant-design/icons";

type RequiredMark = boolean | 'optional';

function Login() {

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');

    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    }

    const colorLoginText = "#09154C"
    const colorLoginButtonBg = "#7ED957"
    const colorLoginGoogleBtnBg = "rgba(0,0,0,0.0)"
    const colorLoginGoogleBtn = "#D93025"

    return (
        <div className={"Login"} style={{marginBottom: '-5px', position: 'relative', backgroundImage: `url(${login_bg})`}}>
            <div style={{margin: '40px auto', width: '350px', position: 'relative', color: colorLoginText}}>
                <div style={{backgroundColor: 'white', borderRadius: '10px', padding: '20px'}}>
                    <h1>Se Connecter</h1>
                    <br/>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{ requiredMarkValue: requiredMark }}
                        onValuesChange={onRequiredTypeChange}
                        requiredMark={requiredMark}
                    >
                        <Form.Item style={{color: colorLoginText}} label="Email" required tooltip="This is a required field">
                            <Input placeholder="Adresse Email" />
                        </Form.Item>
                        <Form.Item style={{color: colorLoginText}} label="Mot de Passe" required tooltip="This is a required field">
                            <Input.Password placeholder="Mot de passe" />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox style={{color: colorLoginText}}>Se souvenir de moi</Checkbox>
                            <a style={{float: 'right', color: colorLoginText}}><b>Mot de passe oublié ?</b></a>
                        </Form.Item>
                        <Form.Item>
                            <Button style={{color: colorLoginText, backgroundColor: colorLoginButtonBg, width: '100%', height: '50px'}} type="primary">Connexion</Button>
                        </Form.Item>
                    </Form>
                    <div style={{textAlign: 'center'}}>
                        <p style={{display: 'inline-block'}}>Pas encore inscrit ?</p>
                        <a style={{display: 'inline-block', marginLeft: '10px', color: colorLoginText,}}><b>Créer un compte</b></a>
                    </div>
                    <br/>
                    <Divider>ou</Divider>
                    <Button style={{border: '1px solid #D93025', backgroundColor: colorLoginGoogleBtnBg, width: '100%', height: '50px'}}><GoogleOutlined style={{color: colorLoginGoogleBtn,}}/></Button>
                </div>
            </div>
        </div>
    );
}

export {Login}